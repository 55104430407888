import React from "react"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.scss"

import { SearchProvider } from "./src/context/SearchContext"

export function shouldUpdateScroll( prevRouterProps, { location }) {
    // console.log( 'Should update scroll...' )
    const element = document.getElementById('___gatsby')
    if ( element ) {
        // element.scrollIntoView({ behavior: 'smooth' })
        window.setTimeout(() => window.scrollTo(0, 0), 10)
    }
    return false
}

// export const onRouteUpdate = ({ location }) => {
//     const element = document.getElementById('___gatsby')
//     if ( element ) {
//         element.scrollIntoView({ behavior: 'smooth' })
//     }
// }

export const wrapRootElement = ({ element }) => (
    <SearchProvider>{element}</SearchProvider>
) 
